"use strict";
/*global document*/
/*global $*/
/*global window*/
/*global navigator, headerApp*/

window.isTouchDevice = function() {
  return "ontouchstart" in window || navigator.maxTouchPoints; // works on IE10/11 and Surface
};

function onPaypalModalClick(event) {
  const clickedOn = $(event.target).closest(".dea-button, .dea-modal__close-button");
  const verifyEmailBtn = clickedOn.closest("#js-confirm-email-trigger");

  if (verifyEmailBtn.length) {
    const users = verifyEmailBtn.data("users").split(",");
    users.forEach(user => {
      headerApp.triggerEmailVerify(user);
    });
    $("#js-email-verify").addClass("dea-modal-overlay--visible");
    $(this).removeClass("dea-modal-overlay--visible");
  } else {
    if (
      clickedOn.hasClass("dea-modal__close-button") ||
      clickedOn.hasClass("dea-modal-overlay--paypal") ||
      clickedOn.hasClass("dea-modal__cancel-button")
    ) {
      $(this).removeClass("dea-modal-overlay--visible");
    }
    if (clickedOn.hasClass("dea-modal__refresh-button")) {
      document.location.href = `${document.location.href}?update=1&${Math.random().toString()}`;
    }
  }
}

function onPaypalModalTriggerClick(event) {
  const modalTemplate = event.data.template;
  const loginUrl = $(this).data("redirect");
  const modalTemplateLoginButton = modalTemplate.find("[data-login-url]");
  modalTemplateLoginButton.attr("href", loginUrl);
  modalTemplate.addClass("dea-modal-overlay--visible");
}

function checkIfAreaPersonaleOrMinisito() {
  return window.location.href.indexOf("areapersonale") > -1 || window.location.href.indexOf("minisito") > -1;
}

$(document).ready(function() {
  // Tabs
  var tabTriggers = $(".dea-tabs-single__input");
  tabTriggers.on("change", function() {
    var target = $(this).data("target");
    var toShow;
    if (target) {
      toShow = $("#" + target);
    } else {
      // If there is no data-target, try to get the target tab content from the id
      toShow = $(
        "#" +
          $(this)
            .attr("id")
            .replace("-trigger", "")
      );
    }
    toShow.addClass("active");
    toShow.siblings().removeClass("active");
  });

  // Read more links
  var readMoreTriggers = $(".js-read-more");
  var readMoreClose = $("#work-description-close");
  readMoreTriggers.on("click", function(e) {
    e.preventDefault();
    var targetEl = $("#work-description-" + $(this).data("target"));
    targetEl.addClass("active");
    $("html,body").animate(
      {
        scrollTop: targetEl.offset().top - $(".site-header").height(),
      },
      "normal"
    );
  });
  readMoreClose.on("click", function() {
    $(this)
      .parents("[id^=work-description]")
      .removeClass("active");
  });

  // Menu variables
  const catalogMenuTrigger = $("#js-catalog-menu-trigger");
  const catalogMenu = $("#js-catalog-menu");
  const subMenuTrigger = $(".js-dea-main-header-submenu-trigger");

  function toggleMenu(element, trigger) {
    trigger
      .toggleClass("isOpen active")
      .find(".dea-main-header__menu-link-icon")
      .toggleClass("rotate");
    element.toggleClass("isOpen");
    element.toggle();
    element.attr("aria-expanded", element.hasClass("isOpen"));
  }

  // Catalog menu
  catalogMenuTrigger.on("click", function() {
    subMenuTrigger.each((index, item) => {
      const trigger = $(item);
      if (trigger.hasClass("isOpen")) {
        const target = trigger.attr("data-target");
        toggleMenu($(`#${target}`), trigger);
      }
    });
    toggleMenu(catalogMenu, catalogMenuTrigger);
  });

  // Submenu
  subMenuTrigger.on("click", function() {
    const main_trigger = $(this);
    if (catalogMenu.hasClass("isOpen")) {
      toggleMenu(catalogMenu, catalogMenuTrigger);
    }
    subMenuTrigger.each((index, item) => {
      const trigger = $(item);
      if (trigger.hasClass("isOpen") || main_trigger.get(0) === trigger.get(0)) {
        const target = trigger.attr("data-target");
        toggleMenu($(`#${target}`), trigger);
      }
    });
  });

  // Print page buttons
  var printTriggers = $("[data-print-start]");
  printTriggers.on("click", function() {
    window.print();
  });

  $(".resources__section a").on("click", function(e) {
    e.preventDefault();
    $(".resources__item")
      .hide()
      .filter("[rel=" + $(this).attr("data-id") + "]")
      .show();
  });

  if ($(".resources").length === 1 && document.location.hash) {
    $("[data-id=" + document.location.hash.slice(1) + "]").trigger("click");
  }

  // Full page modal
  var fullpageModals = $("[data-fullpage-modal]");
  fullpageModals.on("click", function() {
    var toggleTarget = $(this).data("target");
    if (toggleTarget === undefined) {
      return true;
    }

    $("#" + toggleTarget).addClass("active");
  });

  var fullpageModalCloseButtons = $("[data-fullpage-modal-close]");
  fullpageModalCloseButtons.on("click", function() {
    var modalElement = $(this).parents(".fullpage-modal");
    modalElement.removeClass("active");
  });

  // AED Slider (the one in homepage)
  var aedslider = $("#js-aedslider");
  var aedsliderSlides = $(".aedslider__slide .dea-card-strumenti", aedslider);

  aedsliderSlides.on("click", function() {
    var thisSlide = $(this);
    var thisSlideIndex = thisSlide.data("aedslider-index");
    var thisSlideClones = $("[data-aedslider-index=" + thisSlideIndex + "]");
    thisSlideClones.next().toggle();
  });

  aedsliderSlides.each(function() {
    $(this).css("margin-top", function() {
      return Math.floor(Math.random() * (180 - 60 + 1) + 60) + "px";
    });
  });

  if (aedslider.length) {
    aedslider.slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 0,
      speed: 2600,
      arrows: false,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 540,
          settings: {
            slidesToShow: 2,
          },
        },
      ],
    });
  }

  aedslider.mouseleave(function() {
    aedslider.slick("slickPlay");
  });

  function headerHeight() {
    var headerComplete = $("dea-top-header");
    var headerCompleteHeight = headerComplete.height();
    return headerCompleteHeight;
  }

  // Deeplinking
  var hash = window.location.hash;
  if (!hash.startsWith("#/")) {
    var deeplinkedElement = $(hash).first();

    if (deeplinkedElement.length > 0) {
      var elementToScroll = window;

      // If the element is inside an accordion, open it
      var parentAccordion = deeplinkedElement.parents(".dea-accordion-tab").first();
      if (parentAccordion.length > 0) {
        $("[type=checkbox]", parentAccordion).attr("checked", true);
      }

      // If the element is inside a tab, open it
      var parentTab = deeplinkedElement.parents(".dea-tabs-content").first();
      if (parentTab.length > 0) {
        var tabContentId = parentTab.attr("id");
        $("#id_" + tabContentId).attr("checked", true);
        $("#" + tabContentId)
          .addClass("active")
          .siblings()
          .removeClass("active");
      }

      // This function works everywhere, while the next lines works only for work compositions
      var workCompositionTrigger = $('[data-target="work-compositions"]');
      if (workCompositionTrigger.css("display") === "block") {
        workCompositionTrigger.click();
        elementToScroll = $("#work-compositions")[0];
      }

      window.setTimeout(function() {
        elementToScroll.scrollTo(0, deeplinkedElement.offset().top - headerHeight());
      }, 600);
    }
  }

  // Paypal modals
  const paypalModalTriggers = $("[data-modal-buy-on-paypal]");
  const paypalModalTemplate = $("#js-paypal-modal-template");
  const emailVerifyModal = $("#js-email-verify");
  paypalModalTemplate.on("click", onPaypalModalClick);
  emailVerifyModal.on("click", onPaypalModalClick);
  paypalModalTriggers.on("click", { template: paypalModalTemplate }, onPaypalModalTriggerClick);

  // Ebook Download
  const bookLinkEl = $(".ebook-link");
  bookLinkEl.on("click", function() {
    // disabled links won't trigger this due to the removed pointer-events
    var siblings = $(this)
      .parents(".dea-lancio")
      .find(".ebook-link");

    bookLinkEl.not(siblings).addClass("ebook-link--disabled");
  });

  // Search Overlay
  $(".dea-autosuggest__input").on("click", function() {
    $(".search-overlay").fadeIn("slow");
  });

  $(".search-overlay").on("click", function() {
    $(this).fadeOut("slow");
  });

  // Scroll to id when anchor link is clicked
  if (!checkIfAreaPersonaleOrMinisito()) {
    $('a[href^="#"]').on("click", function(event) {
      if ($(this).attr("href") !== "#") {
        event.preventDefault();
        $("html, body").animate(
          {
            scrollTop: $($(this).attr("href")).offset().top - headerHeight(),
          },
          600,
          "linear"
        );
      }
    });
  }
});

// Parallaxes
// This code is copied from sketchin codebase, with minor refactoring by Carlo.
function getPosition(element) {
  var xPosition = 0;
  var yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }

  return { x: xPosition, y: yPosition };
}

function moveLayer(element) {
  element.scrolled = element.startingPos - window.pageYOffset;
  element.layers.forEach(function(layer) {
    var img = layer.querySelector("img");
    img.style.transform = "translateY(" + element.scrolled * Number(layer.getAttribute("data-parallax-speed")) + "px)";
  });
}

function parallax(selector) {
  var el = document.querySelectorAll(selector);
  if (el.length === 0) {
    return;
  }

  el.forEach(function(element) {
    var pos = getPosition(element);
    element.pos = pos;
    element.startingPos = pos.y;
    element.layers = element.querySelectorAll(selector + "__layer");
  });

  window.addEventListener("resize", function() {
    el.forEach(function(element) {
      var pos = getPosition(element);
      element.pos = pos;
      element.startingPos = pos.y;
      moveLayer(element);
    });
  });

  window.addEventListener("scroll", function() {
    el.forEach(function(element) {
      moveLayer(element);
    });
  });
}

parallax(".dea-hero");
parallax(".dea-template-formazione");
